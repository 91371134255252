@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
.hbe__ltr {
  text-align: left;
}
.hbe__rtl {
  text-align: right;
}
.hbe__paragraph {
  margin: 0;
  position: relative;
  /* z-index: 99999999999999999999999; */
}
.hbe__quote {
  margin: 0;
  margin-left: 20px;
  font-size: 1.1rem;
  font-weight: 500;
  color: rgb(13, 46, 114);
  border-left-color: rgb(155, 185, 244);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}
.hbe__quote + :not(.hbe__quote) {
  margin-top: 10px;
}
:not(.hbe__quote) + .hbe__quote {
  margin-top: 10px;
}
.hbe__h1 {
  font-size: 32px;
  font-weight: 700;
}

.hbe__h2 {
  font-size: 28px;
  font-weight: 700;
}

.hbe__h3 {
  font-size: 24px;
  font-weight: 700;
}

.hbe__h4 {
  font-size: 20px;
  font-weight: 700;
}

.hbe__h5 {
  font-size: 18px;
  font-weight: 700;
}

.hbe__h6 {
  font-weight: 700;
  font-size: 16px;
}
.hbe__indent {
  --lexical-indent-base-value: 40px;
}
.hbe__textBold {
  font-weight: bold;
}
.hbe__textItalic {
  font-style: italic;
}
.hbe__textUnderline {
  text-decoration: underline;
}
.hbe__textStrikethrough {
  text-decoration: line-through;
}
.hbe__textUnderlineStrikethrough {
  text-decoration: underline line-through;
}
.hbe__textSubscript {
  font-size: 0.8em;
  vertical-align: sub !important;
}
.hbe__textSuperscript {
  font-size: 0.8em;
  vertical-align: super;
}

.hbe__hashtag {
  background-color: rgba(88, 144, 255, 0.15);
  border-bottom: 1px solid rgba(88, 144, 255, 0.3);
}
.hbe__link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}
.hbe__link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.hbe__code {
  background-color: rgb(186, 219, 217);
  color: #403371;
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 40px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  overflow-x: auto;
  position: relative;
  tab-size: 2;
  white-space: nowrap;
  padding-bottom: 50px;
}
.hbe__code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #283862;
  color: #eee;
  left: 0;
  top: 0;
  bottom: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}
.hbe__textCode {
  background-color: rgb(186, 219, 217);
  color: #403371;
  padding: 0.15rem 0.4rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}
.hbe__textCode {
  margin: 0;
}
.hbe__table {
  border-collapse: collapse;
  border-spacing: 0;
  overflow-y: scroll;
  overflow-x: scroll;
  table-layout: fixed;
  width: max-content;
  margin: 30px 0;
}
.hbe__tableSelection *::selection {
  background-color: transparent;
}
.hbe__tableSelected {
  outline: 2px solid rgb(60, 132, 244);
}
.hbe__tableCell {
  border: 1px solid #bbb;
  width: 75px;
  min-width: 75px;
  vertical-align: top;
  text-align: start;
  padding: 6px 8px;
  position: relative;
  outline: none;
}
.hbe__tableCellSortedIndicator {
  display: block;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #999;
}
.hbe__tableCellResizer {
  position: absolute;
  right: -4px;
  height: 100%;
  width: 8px;
  cursor: ew-resize;
  z-index: 10;
  top: 0;
}
.hbe__tableCellHeader {
  background-color: #f2f3f5;
  text-align: start;
}
.hbe__tableCellSelected {
  background-color: #c9dbf0;
}
.hbe__tableCellPrimarySelected {
  border: 2px solid rgb(60, 132, 244);
  display: block;
  height: calc(100% - 2px);
  position: absolute;
  width: calc(100% - 2px);
  left: -1px;
  top: -1px;
  z-index: 2;
}
.hbe__tableCellEditing {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}
.hbe__tableAddColumns {
  position: absolute;
  top: 0;
  width: 20px;
  background-color: #eee;
  height: 100%;
  right: -25px;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.hbe__tableAddColumns:after {
  background-image: url(./images/icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.hbe__tableAddColumns:hover {
  background-color: #c9dbf0;
}
.hbe__tableAddRows {
  position: absolute;
  bottom: -25px;
  width: calc(100% - 25px);
  background-color: #eee;
  height: 20px;
  left: 0;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.hbe__tableAddRows:after {
  background-image: url(./images/icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.hbe__tableAddRows:hover {
  background-color: #c9dbf0;
}
@keyframes table-controls {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.hbe__tableCellResizeRuler {
  display: block;
  position: absolute;
  width: 1px;
  background-color: rgb(60, 132, 244);
  height: 100%;
  top: 0;
}
.hbe__tableCellActionButtonContainer {
  display: block;
  right: 5px;
  top: 6px;
  position: absolute;
  z-index: 4;
  width: 20px;
  height: 20px;
}
.hbe__tableCellActionButton {
  background-color: #eee;
  display: block;
  border: 0;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  color: #222;
  cursor: pointer;
}
.hbe__tableCellActionButton:hover {
  background-color: #ddd;
}
.hbe__characterLimit {
  display: inline;
  background-color: #ffbbbb !important;
}
.hbe__ol1 {
  list-style-type: decimal;
  padding: 0;
  margin: 0;
  list-style-position: outside;
}
.hbe__ol2 {
  padding: 0;
  margin: 0;
  list-style-type: upper-alpha;
  list-style-position: outside;
}
.hbe__ol3 {
  padding: 0;
  margin: 0;
  list-style-type: lower-alpha;
  list-style-position: outside;
}
.hbe__ol4 {
  padding: 0;
  margin: 0;
  list-style-type: upper-roman;
  list-style-position: outside;
}
.hbe__ol5 {
  padding: 0;
  margin: 0;
  list-style-type: lower-roman;
  list-style-position: outside;
}
.hbe__ul {
  list-style: disc;
  padding: 0;
  margin: 0;
  list-style-position: outside;
}
.hbe__ul2 {
  list-style: circle;
}
.hbe__ul3 {
  list-style: square;
}
.hbe__ul4 {
  list-style: '>> ';
}
.hbe__ul5 {
  list-style: '- ';
}
.hbe__listItem {
  margin: 0 32px;
}
.hbe__listItemChecked,
.hbe__listItemUnchecked {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}
.hbe__listItemChecked {
  text-decoration: line-through;
}
.hbe__listItemUnchecked:before,
.hbe__listItemChecked:before {
  content: '';
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}
.hbe__listItemUnchecked[dir='rtl']:before,
.hbe__listItemChecked[dir='rtl']:before {
  left: auto;
  right: 0;
}
.hbe__listItemUnchecked:focus:before,
.hbe__listItemChecked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}
.hbe__listItemUnchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}
.hbe__listItemChecked:before {
  border: 1px solid rgb(61, 135, 245);
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}
.hbe__listItemChecked:after {
  content: '';
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  right: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}
.hbe__nestedListItem {
  list-style-type: none;
}
.hbe__nestedListItem:before,
.hbe__nestedListItem:after {
  display: none;
}
.hbe__tokenComment {
  color: slategray;
}
.hbe__tokenPunctuation {
  color: #999;
}
.hbe__tokenProperty {
  color: #905;
}
.hbe__tokenSelector {
  color: #690;
}
.hbe__tokenOperator {
  color: #9a6e3a;
}
.hbe__tokenAttr {
  color: #07a;
}
.hbe__tokenVariable {
  color: #e90;
}
.hbe__tokenFunction {
  color: #dd4a68;
}
.hbe__mark {
  background: rgba(255, 212, 0, 0.14);
  border-bottom: 2px solid rgba(255, 212, 0, 0.3);
  padding-bottom: 2px;
}
.hbe__markOverlap {
  background: rgba(255, 212, 0, 0.3);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.hbe__mark.selected {
  background: rgba(255, 212, 0, 0.5);
  border-bottom: 2px solid rgba(255, 212, 0, 1);
}
.hbe__markOverlap.selected {
  background: rgba(255, 212, 0, 0.7);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.hbe__embedBlock {
  user-select: none;
}
.hbe__embedBlockFocus {
  outline: 2px solid rgb(60, 132, 244);
}
.hbe__layoutContainer {
  display: grid;
  gap: 10px;
  margin: 10px 0;
}
.hbe__layoutItem {
  border: 1px dashed #ddd;
  padding: 8px 16px;
}
.hbe__autocomplete {
  color: #ccc;
}
.hbe__hr {
  padding: 2px 2px;
  border: none;
  margin: 1em 0;
  cursor: pointer;
}
.hbe__hr:after {
  content: '';
  display: block;
  height: 2px;
  background-color: #8d8c90;
  line-height: 2px;
}
.hbe__hr.selected {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}
