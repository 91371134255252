i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-left: 4px;
  background-image: url(images/icons/chevron-down.svg);
}
.icon.paragraph {
  background-image: url(images/icons/text-paragraph.svg);
}

.icon.h1 {
  background-image: url(images/icons/type-h1.svg);
}

.icon.h2 {
  background-image: url(images/icons/type-h2.svg);
}

.icon.h3 {
  background-image: url(images/icons/type-h3.svg);
}

.icon.h4 {
  background-image: url(images/icons/type-h4.svg);
}

.icon.h5 {
  background-image: url(images/icons/type-h5.svg);
}

.icon.h6 {
  background-image: url(images/icons/type-h6.svg);
}

.icon.bullet-list,
.icon.bullet {
  background-image: url(images/icons/list-ul.svg);
}

.icon.check-list,
.icon.check {
  background-image: url(images/icons/square-check.svg);
}

.icon.numbered-list,
.icon.number {
  background-image: url(images/icons/list-ol.svg);
}

.icon.quote {
  background-image: url(images/icons/chat-square-quote.svg);
}

i.undo {
  background-image: url(images/icons/arrow-counterclockwise.svg);
}

i.redo {
  background-image: url(images/icons/arrow-clockwise.svg);
}

i.bold {
  background-image: url(images/icons/type-bold.svg);
}

i.custom {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" stroke="currentColor" stroke-width="2" d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path fill="none" stroke="currentColor" stroke-width="2" d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>');
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  width: 24px;
  height: 24px;
  display: inline-block; */
}
i.italic {
  background-image: url(images/icons/type-italic.svg);
}

i.clear {
  background-image: url(images/icons/trash.svg);
}

i.underline {
  background-image: url(images/icons/type-underline.svg);
}

i.strikethrough {
  background-image: url(images/icons/type-strikethrough.svg);
}
i.deeptag {
  background-image: url(images/icons/deeptag.svg);
}
i.subscript {
  background-image: url(images/icons/type-subscript.svg);
}

i.superscript {
  background-image: url(images/icons/type-superscript.svg);
}

i.code {
  background-image: url(images/icons/code.svg);
}

.icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.icon.code {
  background-image: url(images/icons/code.svg);
}

.icon.dropdown-more {
  background-image: url(images/icons/dropdown-more.svg);
}

.icon.font-color {
  background-image: url(images/icons/font-color.svg);
}

.icon.font-family {
  background-image: url(images/icons/font-family.svg);
}

.icon.bg-color {
  background-image: url(images/icons/bg-color.svg);
}

i.palette {
  background-image: url(images/icons/palette.svg);
}

i.bucket {
  background-image: url(images/icons/paint-bucket.svg);
}

i.link {
  background-image: url(images/icons/link.svg);
}

i.horizontal-rule {
  background-image: url(images/icons/horizontal-rule.svg);
}

.icon.plus {
  background-image: url(images/icons/plus.svg);
}

.icon.caret-right {
  background-image: url(images/icons/caret-right-fill.svg);
}

.icon.left-align,
i.left-align {
  background-image: url(images/icons/text-left.svg);
}

.icon.center-align,
i.center-align {
  background-image: url(images/icons/text-center.svg);
}

.icon.right-align,
i.right-align {
  background-image: url(images/icons/text-right.svg);
}

.icon.justify-align,
i.justify-align {
  background-image: url(images/icons/justify.svg);
}

i.indent {
  background-image: url(images/icons/indent.svg);
}

i.markdown {
  background-image: url(images/icons/markdown.svg);
}

i.outdent {
  background-image: url(images/icons/outdent.svg);
}

.icon.table {
  background-color: #6c757d;
  mask-image: url(images/icons/table.svg);
  -webkit-mask-image: url(images/icons/table.svg);
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-size: contain;
}

i.image {
  background-image: url(images/icons/file-image.svg);
}

i.table {
  background-image: url(images/icons/table.svg);
}

i.close {
  background-image: url(images/icons/close.svg);
}

i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 12px;
  width: 12px;
  background-image: url(images/icons/chevron-down.svg);
}
i.columns {
  background-image: url(images/icons/3-columns.svg);
}
i.tweet {
  background-image: url(images/icons/tweet.svg);
}

i.youtube {
  background-image: url(images/icons/youtube.svg);
}
i.figma {
  background-image: url(images/icons/figma.svg);
}
