.search-input-container {
  position: relative;
  width: 100%;
}

.input-container {
  position: relative;
}

.input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #d1d5db;
  padding: 8px;
  transition: border-color 0.3s;
}

.text-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 9999999;
}

.option {
  padding: 8px;
  cursor: pointer;
}

.option:hover {
  background-color: #f3f4f6;
}

.option-text {
  display: block;
}

.loading,
.no-result {
  padding: 16px;
  text-align: center;
}
