.editor-container.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: #fff;
}

.full-screen .editor-wrapper {
  height: 100%;
  width: 100%;
}

.full-screen .fullscreen-toggle {
  /* position: absolute; */
  top: 10px;
  right: 10px;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1001;
  margin: 10px;
}

.editor-wrapper {
  position: relative;
}
.contentEditable {
  min-height: 200px;
  width: 100%;
  padding: 12px 12px 40px;
  font-size: 15px;
  display: block;
  position: relative;
  outline: 0;
  overflow: auto;
}

.contentEditable.full-screen {
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  padding: 12px 12px 40px;
  font-size: 15px;
  display: block;
  position: relative;
  outline: 0;
  overflow: auto;
}

.contentEditable.editable {
  border: 1.5px solid #c3c1cd;
  border-radius: 12px;
}

.placeholder {
  position: absolute;
  top: 0px;
  padding: 12px 12px;
  color: #808080;
  user-select: none;
  pointer-events: none;
}
.img-contentEditable {
  min-height: 40px;
  width: 100%;
  padding: 10px 10px 10px;
  font-size: 14px;
  display: block;
  position: relative;
  outline: 0;
}

.img-placeholder {
  position: absolute;
  top: 0px;
  padding: 12px 12px;
  color: #808080;
  user-select: none;
  pointer-events: none;
  z-index: 10;
}

.toolbar {
  display: flex;
  margin-bottom: 3px;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
  overflow: auto;
  height: 36px;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 0px 12px 5px;
}

button.toolbar-item {
  border: 0;
  display: flex;

  background: none;
  border-radius: 10px;
  padding: 6px;
  cursor: pointer;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

button.toolbar-product {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 6px;
  cursor: pointer;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

button.toolbar-product.spaced {
  margin-right: 2px;
}

button.toolbar-item:disabled {
  cursor: not-allowed;
}

button.toolbar-item.spaced {
  margin-right: 2px;
}

button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 16px;
  width: 16px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

button.toolbar-item:disabled .icon,
button.toolbar-item:disabled .text,
button.toolbar-item:disabled i.format,
button.toolbar-item:disabled .chevron-down {
  opacity: 0.2;
}

button.toolbar-item.active {
  background-color: rgba(143, 144, 146, 0.495);
}

button.toolbar-item.active i {
  opacity: 1;
}

.toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.toolbar .divider {
  width: 1px;
  background-color: #d5d0d0;
  margin: 0 4px;
  flex-shrink: 0;
}
.format.custom-link {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" stroke="currentColor" stroke-width="2" d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path fill="none" stroke="currentColor" stroke-width="2" d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>');
}
.table-cell-action-button-container {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
}

.table-cell-action-button {
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  position: relative;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}
.dialog-dropdown {
  background-color: #fff !important;
  margin-bottom: 10px;
  width: 100%;
}

.action-button {
  background-color: #eee;
  border: 0;
  padding: 8px 12px;
  position: relative;
  margin-left: 5px;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}

.action-button:hover {
  background-color: #ddd;
  color: #000;
}

.action-button-mic.active {
  animation: mic-pulsate-color 3s infinite;
}

button.action-button:disabled {
  opacity: 0.6;
  background: #eee;
  cursor: not-allowed;
}

@keyframes mic-pulsate-color {
  0% {
    background-color: #ffdcdc;
  }

  50% {
    background-color: #ff8585;
  }

  100% {
    background-color: #ffdcdc;
  }
}

.editor-wrapper span.editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
}

.editor-wrapper .editor-image img {
  max-width: 100%;
  cursor: default;
}

.editor-wrapper .editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

.editor-wrapper .editor-image img.focused.draggable {
  cursor: grab;
}

.editor-wrapper .editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.editor-wrapper .editor-image .image-caption-container .tree-view-output {
  margin: 0;
  border-radius: 0;
}

.editor-wrapper .editor-image .image-caption-container {
  display: block;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  border-top: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  min-width: 100px;
  color: #000;
  overflow: hidden;
}

.editor-wrapper .editor-image .image-caption-button {
  display: block;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 30%;
  padding: 10px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 100px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

.editor-wrapper .editor-image .image-caption-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}

.editor-wrapper .editor-image .image-edit-button {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-image: url(./images/icons/pencil-fill.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
  user-select: none;
}

.editor-wrapper .editor-image .image-edit-button:hover {
  background-color: rgba(60, 132, 244, 0.1);
}

.editor-wrapper .editor-image .image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
}

.editor-wrapper .editor-image .image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

.editor-wrapper .editor-image .image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

.editor-wrapper .editor-image .image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

.editor-wrapper .editor-image .image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

.editor-wrapper .editor-image .image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

.editor-wrapper .editor-image .image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

.editor-wrapper .editor-image .image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

.editor-wrapper .editor-image .image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

.editor-wrapper span.inline-editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.editor-wrapper .inline-editor-image img {
  max-width: 100%;
  cursor: default;
}

.editor-wrapper .inline-editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
}

.editor-wrapper .inline-editor-image img.focused.draggable {
  cursor: grab;
}

.editor-wrapper .inline-editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.image-caption-container {
  position: relative;
}
.editor-wrapper
  .inline-editor-image
  .image-caption-container
  .tree-view-output {
  margin: 0;
  border-radius: 0;
}

.editor-wrapper .inline-editor-image.position-full {
  margin: 0 auto;
  margin: 1em 0 1em 0;
  width: 100%;
}

.editor-wrapper .inline-editor-image.position-full img {
  width: 100% !important;
}

.editor-wrapper .inline-editor-image.position-left {
  width: 50%;
  margin: 1em 1em 0 0;
  float: left;
}

.editor-wrapper .inline-editor-image.position-right {
  width: 50%;
  margin: 1em 0 0 1em;
  float: right;
}

.editor-wrapper .inline-editor-image .image-edit-button {
  display: block;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 6px 8px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 60px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

.editor-wrapper .inline-editor-image .image-edit-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}

.editor-wrapper .inline-editor-image .image-caption-container {
  display: block;
  background-color: #f4f4f4;
  min-width: 100%;
  color: #000;
  overflow: hidden;
}

.emoji {
  color: transparent;
  caret-color: rgb(5, 5, 5);
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}

.keyword {
  color: rgb(28, 0, 138);
  font-weight: bold;
}

.typeahead-popover {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: fixed;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.typeahead-popover ul li.selected {
  background: #eee;
}

.typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.typeahead-popover li:hover {
  background-color: #eee;
}

.typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.typeahead-popover li .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.auto-embed-menu {
  width: 150px;
}
