.font-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.font-size-input {
  font-weight: bold;
  font-size: 14px;
  color: #777;
  border-radius: 5px;
  border-color: grey;
  height: 21px;
  padding: 2px 4px;
  text-align: center;
  width: 50px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.add-icon {
  background-image: url(../../images/icons/add-sign.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.minus-icon {
  background-image: url(../../images/icons/minus-sign.svg);
  background-repeat: no-repeat;
  background-position: center;
}

button.font-decrement {
  padding: 5px;
}

button.font-increment {
  padding: 5px;
}
